import banma from '@/asset/看云历程/斑马.svg'
import feixiang from '@/asset/看云历程/飞象星球.svg'
import kanyun from '@/asset/看云历程/看云控股.svg'
import moli from '@/asset/看云历程/茉莉智慧.svg'
import nangua from '@/asset/看云历程/南瓜科学.svg'
import kousuan from '@/asset/看云历程/小猿口算.svg'
import souti from '@/asset/看云历程/小猿搜题.svg'
import biancheng from '@/asset/看云历程/猿编程.svg'
import fudao from '@/asset/看云历程/猿辅导.svg'
import coffe from '@/asset/看云历程/GRID COFFE.svg'
import sky from '@/asset/看云历程/SKYPEOPLE.svg'
import haitun from '@/asset/看云历程/海豚自习.svg'
import xuelianji from '@/asset/看云历程/xuelianji.svg'

import banmaMobile from '@/asset/mobile/experience/banma.svg'
import feixiangMobile from '@/asset/mobile/experience/feixiang.svg'
import kanyunMobile from '@/asset/mobile/experience/kanyun.svg'
import moliMobile from '@/asset/mobile/experience/moli.svg'
import nanguaMobile from '@/asset/mobile/experience/nangua.svg'
import kousuanMobile from '@/asset/mobile/experience/kousuan.svg'
import soutiMobile from '@/asset/mobile/experience/souti.svg'
import bianchengMobile from '@/asset/mobile/experience/biancheng.svg'
import fudaoMobile from '@/asset/mobile/experience/yfd.svg'
import coffeMobile from '@/asset/mobile/experience/coffee.svg'
import skyMobile from '@/asset/mobile/experience/sky.svg'
import xuelianjiMobile from '@/asset/mobile/experience/xuelianji.svg'
import { isMobile } from '@/utils/common'

export const experienceImgs = isMobile() ? {
  banma: banmaMobile,
  feixiang: feixiangMobile,
  kanyun: kanyunMobile,
  moli: moliMobile,
  haitun: 'https://metis-online.fbcontent.cn/metis-lectio/kanyun/kznqfHAbClFH9scFsVWhPZ.png',
  nangua: nanguaMobile,
  kousuan: kousuanMobile,
  souti: soutiMobile,
  biancheng: bianchengMobile,
  fudao: fudaoMobile,
  coffe: coffeMobile,
  sky: skyMobile,
  xuelianji: xuelianjiMobile,
} : {
  banma,
  feixiang,
  kanyun,
  moli,
  haitun,
  nangua,
  kousuan,
  souti,
  biancheng,
  fudao,
  coffe,
  sky,
  xuelianji,
}

export const experienceImgHeight = isMobile() ? {
  banma: '.3rem',
  feixiang: '.32rem',
  kanyun: '.24rem',
  moli: '.4rem',
  haitun: '.3rem',
  nangua: '.3rem',
  kousuan: '.24rem',
  souti: '.24rem',
  biancheng: '.33rem',
  fudao: '.26rem',
  coffe: '.34rem',
  sky: '.17rem',
  xuelianji: '.23rem',
} : {
  banma: 'auto',
  feixiang: 'auto',
  kanyun: 'auto',
  moli: 'auto',
  haitun: 'auto',
  nangua: 'auto',
  kousuan: 'auto',
  souti: 'auto',
  biancheng: 'auto',
  fudao: 'auto',
  coffe: 'auto',
  sky: 'auto',
  xuelianji: 'auto',
}
